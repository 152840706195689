







































import { Component, PropSync, Vue } from 'vue-property-decorator'

import { AccordionItem } from '../../../../dsl/atoms/Accordion'
import { HeadingForm } from '../../../partials/HeadingForm'
import { MarkdownEditor } from '../../../partials/MarkdownEditor'

/**
 * Single item tab for accordion module form.
 *
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl> (original)
 */
@Component<ItemTab>({
  name: 'SlideTab',
  components: { HeadingForm, MarkdownEditor }
})
export class ItemTab extends Vue {
  @PropSync('item', { type: Object, required: true })
  public _item!: AccordionItem

  public isOpen: boolean = this._item?.isOpen ?? false

  public onUpdateIsOpen (open: boolean): void {
    this.isOpen = open
    this._item.isOpen = open
  }
}

export default ItemTab
