export enum TimerAddonXPositionOption {
  Left = 'left',
  Right = 'right',
  Center = 'center'
}

export enum TimerAddonYPositionOption {
  Top = 'top',
  Bottom = 'bottom',
  Center = 'center'
}
