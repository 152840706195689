





































import { Component, Prop } from 'vue-property-decorator'

import { PickerCallback, PossibleRelatedPicker } from '../../../contexts'

import { AbstractModuleForm } from '../../abstract/form'
import { FormAddBtn } from '../../partials/FormAddBtn'
import { FormFieldset } from '../../partials/FormFieldset'
import { HeadingForm } from '../../partials/HeadingForm'
import { Iterator } from '../../partials/Iterator'
import { SetForm } from '../../partials/SetForm'
import {
  SpecialHeroModule,
  SpecialHeroModuleContent,
  SpecialHeroModuleContentInput
} from '../SpecialHero.contracts'
import { specialHeroContentFactory } from '../SpecialHero.factory'
import { UnresolvedImage } from '../../partials'
import { ImageForm } from '../../partials/ImageForm'
import SpecialHeroItem from './partials/SpecialHeroItem.vue'

/**
 * Form component for the `SpecialHero` module.
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl> (original)
 */
@Component<SpecialHeroModuleForm>({
  name: 'SpecialHeroModuleForm',
  components: {
    FormAddBtn,
    Iterator,
    SpecialHeroItem,
    FormFieldset,
    HeadingForm,
    SetForm,
    ImageForm
  }
})
export class SpecialHeroModuleForm extends AbstractModuleForm<SpecialHeroModuleContentInput, SpecialHeroModule> {
  /**
   * Callback used by form to pick relate.
   */
  @Prop({ type: Function, required: true })
  public readonly pickRelated!: PickerCallback

  /**
   * Determines links teaser initial content.
   */
  public initialContent: SpecialHeroModuleContent = specialHeroContentFactory()

  /**
   * Determines related picker.
   */
  public picker = PossibleRelatedPicker

  /**
   * Method to get background image of hero for <ImageForm>
   */
  public get image (): UnresolvedImage | null {
    if (this.data.background === null) {
      return null
    }
    return {
      image: this.data.background ?? null
    }
  }

  /**
   * Method to set background image of hero chosen from <ImageForm>
   */
  public set image (image: UnresolvedImage | null) {
    if (!image) {
      this.data.background = null
      return
    }
    this.data.background = image.image
  }

  public addNewItem () {
    return Object.create(null)
  }
}

export default SpecialHeroModuleForm
