



































































import { DashmixSelectItem, DateTimePickerType } from '@movecloser/ui-core'
import { Component } from 'vue-property-decorator'

import { ColorSchemaSelector } from '../../../partials/ColorSchemaSelector'
import { FormFieldset } from '../../../partials/FormFieldset'
import { MarkdownEditor } from '../../../partials/MarkdownEditor'

import { AbstractHeroAddon } from './abstract'
import { TimerAddonXPositionOption, TimerAddonYPositionOption } from './config'

/**
 * **TIMER ADDON** for Hero module.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'TimerAddon', components: { FormFieldset, MarkdownEditor, ColorSchemaSelector } })
export class TimerAddon extends AbstractHeroAddon {
  public dateTimePickerType = DateTimePickerType

  public get positionXOptions (): DashmixSelectItem[] {
    return Object.entries(TimerAddonXPositionOption).map(([_, value]) => ({
      label: this.$t(`modulesForms.Hero.addons.timer.position.${value}`).toString(),
      value
    }))
  }

  public get positionYOptions (): DashmixSelectItem[] {
    return Object.entries(TimerAddonYPositionOption).map(([_, value]) => ({
      label: this.$t(`modulesForms.Hero.addons.timer.position.${value}`).toString(),
      value
    }))
  }

  public get sizeSelectorOptions (): DashmixSelectItem[] {
    return Array.from({ length: 56 - 14 + 1 }, (_, i) => 14 + i).map(size => {
      return {
        label: String(size),
        value: size
      }
    })
  }

  public get color (): { color: string } | undefined {
    if (!Object.prototype.hasOwnProperty.call(this._model, 'color')) {
      return { color: 'black' }
    }

    const _modelCopy = this._model

    if (_modelCopy.color === null || typeof _modelCopy.color === 'undefined') {
      return { color: 'black' }
    }

    return { color: _modelCopy.color }
  }

  public set color (contentColor: { color: string } | undefined) {
    if (!contentColor) {
      const slideCopy = { ...this._model }
      slideCopy.color = undefined
      this._model = slideCopy
      return
    }

    this._model = {
      ...this._model,
      color: contentColor.color
    }
  }
}
export default TimerAddon
