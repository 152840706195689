














































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { FormFieldset } from '../../../partials/FormFieldset'
import { HeadingForm } from '../../../partials/HeadingForm'
import { ImageForm } from '../../../partials/ImageForm'
import { LinkForm } from '../../../partials/LinkForm/LinkForm.vue'

import { IRelatedService, PickerCallback } from '../../../../contexts'
import { SpecialLinkInput } from '../../SpecialHero.contracts'
import { MarkdownEditor } from '../../../partials/MarkdownEditor'

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
@Component<SpecialHeroItem>({
  name: 'SpecialHeroItem',
  components: {
    FormFieldset,
    HeadingForm,
    ImageForm,
    LinkForm,
    MarkdownEditor
  }
})
export class SpecialHeroItem extends Vue {
  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  @PropSync('item', { type: Object, required: true })
  public _item!: SpecialLinkInput
}

export default SpecialHeroItem
