




























































import { Component, Watch } from 'vue-property-decorator'
import { DashmixBoxTabItem, DashmixIconName } from '@movecloser/ui-core'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { AbstractModuleForm } from '../../abstract/form'
import { Inject, REFRESH_MDE_KEY } from '../../../support'

import { AccordionItem } from '../../../dsl/atoms/Accordion'
import { ClassForm } from '../../partials/ClassForm'
import { FormFieldset } from '../../partials/FormFieldset'
import { HeadingForm } from '../../partials/HeadingForm'

import { accordionContentFactory } from '../Accordion.factory'

import {
  AccordionModule,
  AccordionModuleContent,
  AccordionModuleContentInput
} from '../Accordion.contracts'

import { ItemTab, ItemTabLabel } from './partials'

/**
 * Form component for the `Accordion` module.
 *
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component<AccordionModuleForm>({
  name: 'AccordionModuleForm',
  components: { ClassForm, FormFieldset, HeadingForm, ItemTab }
})
export class AccordionModuleForm extends AbstractModuleForm<AccordionModuleContentInput, AccordionModule> {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  public initialContent: AccordionModuleContent = accordionContentFactory()

  /**
   * Initial active tab
   */
  public activeTab: string | null = null

  public icons = DashmixIconName

  protected addNewItem (): AccordionItem {
    return {
      id: 'accordion-' + Math.floor(Math.random() * 1000).toString(),
      label: '',
      content: '',
      isOpen: false
    }
  }

  /**
   * Creates new accordion element
   */
  public createItem (): void {
    if (!this.data.items) {
      this.data.items = []
    }
    this.data.items!.push(this.addNewItem())

    this.activeTab = this.data.items[this.data.items.length - 1].id
  }

  public get hasItems (): boolean {
    return Array.isArray(this.items) && this.items.length > 0
  }

  public get items (): DashmixBoxTabItem[] {
    if (!this.data.items) {
      return []
    }

    return [...this.data.items.map((item, index) => {
      return {
        props: {
          item,
          index
        },
        tab: {
          id: `${item.id}`,
          disabled: false,
          label: ItemTabLabel,
          props: {
            tabIndex: index,
            label: `${this.$t('modulesForms.Accordion.items.tab.label')} ${index + 1}`,
            onItemRemove: this.removeItem
          }
        }
      }
    })]
  }

  public updateItem (item: AccordionItem, index: number):void {
    if (typeof this.data.items === 'undefined' || !Array.isArray(this.data.items)) {
      throw new Error('[AccordionModuleForm]: Try to update item in not existing item list!')
    }

    const contentItemCopy: AccordionItem[] = [...this.data.items]
    contentItemCopy[index] = item
    this.data.items = contentItemCopy
  }

  private removeItem (index: number): void {
    if (typeof this.data.items === 'undefined' || !Array.isArray(this.data.items)) {
      throw new Error(
        '[AccordionModuleForm]: Try to remove item from not existing item list!')
    }

    this.data.items.splice(index, 1)

    if (this.data.items.length > 0) {
      if (index !== 0) {
        this.activeTab = this.items[index - 1].tab.id.toString()
      } else {
        this.activeTab = this.items[index].tab.id.toString()
      }
    } else {
      this.activeTab = null
    }
  }

  @Watch('activeTab')
  protected onActiveTabChange (): void {
    this.eventBus.emit(REFRESH_MDE_KEY)
  }
}
export default AccordionModuleForm
